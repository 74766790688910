/*
 * File: app.module.ts
 * Project: UTU - A collaborative agency
 * Created: Thursday, October 19th 2023, 4:42:53 am
 * Last Modified: Thursday, March 7th 2024, 1:47:11 pm
 * Copyright © 2019 UTU, Lda
 */

import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LayoutModule } from "./layout/layout.module";
import { HttpClientModule } from "@angular/common/http";
import { SharedMaterialModule } from "./shared-material.module";
import { FormsModule } from "@angular/forms";
import { JwtModule } from "@auth0/angular-jwt";
import { ToastrModule } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatRadioModule } from "@angular/material/radio";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    LayoutModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      closeButton: false,
      progressBar: true,
      newestOnTop: false,
      positionClass: "toast-top-center",
      preventDuplicates: true,
    }),
    JwtModule.forRoot({
      config: {
        headerName: "X-Authorization",
        tokenGetter,
        allowedDomains: environment.tokenWhitelistedDomains,
        disallowedRoutes: environment.tokenBlacklistedRoutes,
      },
    }),
    MatRadioModule,
    SharedMaterialModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "pt" },
    { provide: LOCALE_ID, useValue: "pt" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function tokenGetter() {
  return localStorage.getItem("token");
}
