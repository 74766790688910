import { Component, OnInit, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { COMPANY_NAME } from 'src/app/constants';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-classic',
  templateUrl: './classic.component.html',
  styleUrls: ['./classic.component.scss']
})
export class ClassicComponent implements OnInit, AfterViewInit {

  @ViewChild("chatContainer", { read: ViewContainerRef, static: true }) chatContainer;

  loadingRouteConfig: boolean;

  pageheader;

  closedTemplate = true;

  title;
  description;

  children: ActivatedRoute;

  urlsTemplateOpen = ["/chat", "/agenda"];

  fullYear = new Date().getFullYear();

  companyName = COMPANY_NAME;

  constructor(private router: Router, private activeRoute: ActivatedRoute, public globalService: GlobalService) {
    this.getTitle();
    this.loadingRouteConfig = true;
    this.verifyTemplateClose();
  }

  ngAfterViewInit() {
    this.checkOnline();
  }

  checkOnline() {
    // console.log("check online");
    /*  this.globalService.checkOnline()
       .then(result => {
         // console.log("Result check online", result);
       })
       .catch()
       .finally(() => {
         setTimeout(() => {
           this.checkOnline();
         }, 60000);
       }); */

  }


  ngOnInit() {
    // console.log(this.chatContainer);
    this.globalService.chatContainer = this.chatContainer;
    this.loadingRouteConfig = false;
    this.router.events.subscribe(event => {
      // console.log(event, this.activeRoute);
      if (event instanceof NavigationStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof NavigationEnd) {
        this.verifyTemplateClose();
        this.getTitle();
        this.loadingRouteConfig = false;
      }
    });
  }

  private verifyTemplateClose() {
    if (this.urlsTemplateOpen.indexOf(this.router.url) >= 0) {
      this.closedTemplate = false;
    }
    else {
      this.closedTemplate = true;
    }
  }

  private getTitle() {
    if (this.activeRoute.children.length) {
      this.children = this.activeRoute.children[0];
      if (this.children.children.length) {
        this.title = this.children.children[0].data['value'].title;
        this.description = this.children.children[0].data['value'].description;
      }
      else {
        this.title = this.children.data['value'].title;
        this.description = this.children.data['value'].description;
      }
    }
    else {
      this.title = this.activeRoute.data['value'].title;
      this.description = this.activeRoute.data['value'].description;
    }
  }
}
