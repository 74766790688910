<!-- ########## START: LEFT PANEL ########## -->
<div class="br-logo"><a href="#" routerLink="/dashboard" style="margin: 0 auto;"><img width="200"
      src="assets/img/logo.png" class="logo-menu img-responsive"></a></div>
<div class="br-sideleft sideleft-scrollbar  ps ps--active-y">
  <label class="sidebar-label pd-x-10 mg-t-20 op-3">Navegação</label>
  <ul class="br-sideleft-menu">
    <li class="br-menu-item">
      <a routerLink="/dashboard" routerLinkActive="active" class="br-menu-link">
        <i class="menu-item-icon fa fa-th-large"></i>
        <span class="menu-item-label">Inicio</span>
      </a><!-- br-menu-link -->
    </li><!-- br-menu-item -->
    <li class="br-menu-item">
      <a href="#" class="br-menu-link with-sub" [routerLinkActive]="['active', 'show-sub']">
        <i class=" menu-item-icon fa fa-briefcase"></i>
        <span class="menu-item-label">Comercial</span>
      </a><!-- br-menu-link -->
      <ul class="br-menu-sub">
        <li class="sub-item"><a routerLink="/pedido" routerLinkActive="active" *ngIf="hasRole('ROLE_GERENCIAR_PEDIDO')"
            class="sub-link">Contratos</a></li>
        <li class="sub-item"><a routerLink="/cliente" routerLinkActive="active"
            *ngIf="hasRole('ROLE_GERENCIAR_CLIENTE')" class="sub-link">Clientes</a></li>
      </ul>
    </li><!-- br-menu-item -->
    <li class="br-menu-item">
      <a href="#" class="br-menu-link with-sub">
        <i class=" menu-item-icon fas fa-dolly-flatbed"></i>
        <span class="menu-item-label">Logística</span>
      </a><!-- br-menu-link -->
      <ul class="br-menu-sub">
        <li class="sub-item"><a routerLink="/conteiner" *ngIf="hasRole('ROLE_GERENCIAR_CONTEINER')"
            routerLinkActive="active" class="sub-link">Conteiners</a></li>
        <li class="sub-item"><a routerLink="/rotas" *ngIf="hasRole('ROLE_GERENCIAR_ROTAS')" routerLinkActive="active"
            class="sub-link">Rotas</a></li>
        <li class="sub-item"><a routerLink="/item" *ngIf="hasRole('ROLE_GERENCIAR_ITENS')" routerLinkActive="active"
            class="sub-link">Itens</a></li>
      </ul>
    </li><!-- br-menu-item -->
    <li class="br-menu-item">
      <a href="#" class="br-menu-link with-sub">
        <i class=" menu-item-icon fas fa-cog"></i>
        <span class="menu-item-label">Administrativo</span>
      </a><!-- br-menu-link -->
      <ul class="br-menu-sub">
        <li class="sub-item"><a routerLink="/usuario" *ngIf="hasRole('ROLE_CADASTRAR_USUARIO')"
            routerLinkActive="active" class="sub-link">Cadastro de Usuários</a></li>
        <li class="sub-item"><a routerLink="/status" *ngIf="hasRole('ROLE_GERENCIAR_STATUS')" routerLinkActive="active"
            class="sub-link">Status de Envios</a></li>
        <li class="sub-item"><a routerLink="/financeiro" *ngIf="hasRole('ROLE_GERENCIAR_FINANCEIRO')"
            routerLinkActive="active" class="sub-link">Centro de Custos</a></li>
        <li class="sub-item"><a routerLink="/furnitures" *ngIf="hasRole('ROLE_GERENCIAR_FURNITURES')"
            routerLinkActive="active" class="sub-link">Mobiliário</a></li>
        <li class="sub-item"><a routerLink="/address/country" routerLinkActive="active" class="sub-link">Países</a></li>
        <li class="sub-item"><a routerLink="/address/state" routerLinkActive="active" class="sub-link">Estados</a></li>
        <li class="sub-item"><a routerLink="/address/city" routerLinkActive="active" class="sub-link">Cidades</a></li>
      </ul>
    </li><!-- br-menu-item -->
    <li class="br-menu-item">
      <a href="#" class="br-menu-link with-sub">
        <i class=" menu-item-icon fas fa-chart-line"></i>
        <span class="menu-item-label">Financeiro</span>
      </a><!-- br-menu-link -->
      <ul class="br-menu-sub">
        <li class="sub-item"><a routerLink="/relatorio/pedido" *ngIf="hasRole('ROLE_RELATORIO_PEDIDO')"
            routerLinkActive="active" class="sub-link">Relatório de Contratos</a></li>
        <li class="sub-item"><a routerLink="/relatorio/container" *ngIf="hasRole('ROLE_RELATORIO_CONTAINER')"
            routerLinkActive="active" class="sub-link">Relatório de Containers</a></li>
        <li class="sub-item"><a routerLink="/relatorio/comissao" *ngIf="hasRole('ROLE_RELATORIO_COMISSAO')"
            routerLinkActive="active" class="sub-link">Relatório de Comissões</a></li>
      </ul>
    </li><!-- br-menu-item -->

  </ul><!-- br-sideleft-menu -->
  <br>
</div><!-- br-sideleft -->
<!-- ########## END: LEFT PANEL ########## -->