// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The cotainer of file replacements can be found in `angular.json`.
const url = "https://api.myboxplus.com.br";
export const environment = {
  production: false,
  url: url + "/api",
  tokenWhitelistedDomains: ["api.myboxplus.com.br"],
  tokenBlacklistedRoutes: [
    url + "/oauth/token",
    url + "/api/auth/recovery",
    url + "/api/contato",
    url + "/api/register",
    url + "/api/register/validate",
  ],
  client_id: 2,
  client_secret: "t7vfgX4pcN32nPmu7kUXy8apn1ZQe0VBPghn3HVH",
  authorization:
    "Basic Mjp0N3ZmZ1g0cGNOMzJuUG11N2tVWHk4YXBuMVpRZTBWQlBnaG4zSFZI",
  urlSecurity: url,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
